import _extends from "@babel/runtime/helpers/extends";
import { useState, useMemo } from "react";
import { commonInlineStyleBodyTeaching, commonInlineStyleBodyTeachingsSeparator, Cookie, useBlocker, useCommonTeachings, BlockerInlineStyles, BlockerBodyInlineStyles, BlockerGroupsInlineStyles, BlockerButton } from "../../..";
const BlockerBody = () => {
  const blocker = useBlocker();
  const [areCookiesVisible, setCookiesVisible] = useState(false);
  const {
    bodyDesign: {
      teachingsSeparatorActive
    },
    decision: {
      acceptAll,
      acceptIndividual
    },
    texts: {
      blockerLoadButton,
      blockerLinkShowMissing,
      blockerAcceptInfo
    },
    blocker: {
      services
    },
    consent,
    groups,
    onUnblock,
    productionNotice,
    isConsentRecord,
    i18n: {
      close
    }
  } = blocker;

  // Calculate missing cookies
  const missing = useMemo(() => {
    const missing = [];
    const allConsentCookies = [];
    for (const cookies of Object.values(consent.groups)) {
      allConsentCookies.push(...cookies);
    }
    for (const {
      items
    } of groups) {
      for (const cookie of items) {
        // Cookie is needed for this blocker? Yes, check if already consent...
        if (services.indexOf(cookie.id) > -1 && allConsentCookies.indexOf(cookie.id) === -1) {
          missing.push(cookie);
        }
      }
    }
    return missing;
  }, [groups, services, consent]);
  const {
    description,
    teachings
  } = useCommonTeachings({
    services: missing,
    // Not needed in blocker, as there is already a link with "Show all services which I need to consent to"
    disableListServicesNotice: true
  });
  return h("div", BlockerBodyInlineStyles.bodyContainer(blocker), h("div", BlockerBodyInlineStyles.body(blocker), h("div", BlockerInlineStyles.topSide(blocker), h("div", BlockerBodyInlineStyles.description(blocker), h("span", {
    dangerouslySetInnerHTML: {
      __html: description.replace(/\n/gm, "<br />")
    }
  }), !!description && teachingsSeparatorActive && h("div", null, h("span", commonInlineStyleBodyTeachingsSeparator(blocker))), teachings.map(teaching => h("span", _extends({
    key: teaching
  }, commonInlineStyleBodyTeaching(blocker), {
    dangerouslySetInnerHTML: {
      __html: teaching
    }
  }))), h("span", _extends({}, commonInlineStyleBodyTeaching(blocker), {
    dangerouslySetInnerHTML: {
      __html: blockerAcceptInfo
    }
  }))), h(BlockerButton, {
    type: acceptIndividual === "hide" ? "link" : acceptIndividual,
    inlineStyle: "showInfo",
    onClick: () => setCookiesVisible(!areCookiesVisible)
  }, areCookiesVisible ? close : blockerLinkShowMissing), areCookiesVisible && h("div", BlockerGroupsInlineStyles.cookieScroll(blocker), missing.map(cookie => h(Cookie, {
    key: cookie.id,
    cookie: cookie,
    checked: true,
    disabled: true
  })))), h("div", BlockerInlineStyles.bottomSide(blocker), h(BlockerButton, {
    type: acceptAll === "hide" ? "button" : acceptAll,
    inlineStyle: "save",
    onClick: e => onUnblock(e),
    busyOnClick: !isConsentRecord
  }, blockerLoadButton), productionNotice)));
};
export { BlockerBody };