import { useMemo } from "react";
import { useState } from "react";
import { getOtherOptionsFromWindow } from "../utils";
import { adjustConsentComingFromContentBlocker } from "../others";
import { HTML_ATTRIBUTE_COOKIE_IDS } from "@devowl-wp/headless-content-unblocker";
import { getUserDecision } from "@devowl-wp/cookie-consent-web-client";
import { usePlainCss, BlockerContextFactory, Blocker } from "@devowl-wp/react-cookie-banner";
import { Recorder } from "@devowl-wp/web-html-element-interaction-recorder";
import { BannerProductionNotice } from ".";

/**
 * Simply connects a context store to the blocker itself. On the website itself
 * it should not rely
 */
const WebsiteBlocker = _ref => {
  let {
    container,
    poweredLink,
    blocker,
    paintMode,
    setVisualAsLastClickedVisual
  } = _ref;
  const {
    customizeValuesBanner: {
      layout,
      decision,
      legal,
      design,
      headerDesign,
      bodyDesign,
      footerDesign,
      texts,
      individualLayout,
      saveButton,
      group,
      individualTexts,
      customCss
    },
    pageIdToPermalink,
    consentForwardingExternalHosts,
    isTcf,
    isDataProcessingInUnsafeCountries,
    dataProcessingInUnsafeCountriesSafeCountries,
    iso3166OneAlpha2,
    isAgeNotice,
    isListServicesNotice,
    groups,
    userConsentCookieName,
    bannerDesignVersion,
    bannerI18n,
    isPro,
    isLicensed,
    isDevLicense,
    affiliate,
    isCurrentlyInTranslationEditorPreview,
    pageByIdUrl
  } = getOtherOptionsFromWindow();
  const userDecision = getUserDecision(userConsentCookieName);
  const recorder = useMemo(() => new Recorder(container), []);

  // Calculate a border color for the complete box as the standard box does not have one configured
  const overwriteDesign = {
    borderWidth: design.borderWidth || 1,
    borderColor: design.borderWidth === 0 ? headerDesign.borderWidth > 0 ? headerDesign.borderColor : footerDesign.borderWidth > 0 ? footerDesign.borderColor : design.fontColor : design.borderColor
  };

  // Context values preparation
  const [contextValue] = useState({
    recorder,
    layout: {
      ...layout
    },
    decision: {
      ...decision
    },
    legal: {
      ...legal
    },
    design: {
      ...design,
      ...overwriteDesign
    },
    headerDesign: {
      ...headerDesign
    },
    bodyDesign: {
      ...bodyDesign
    },
    footerDesign: {
      ...footerDesign
    },
    texts: {
      ...texts
    },
    individualLayout: {
      ...individualLayout
    },
    saveButton: {
      ...saveButton
    },
    group: {
      ...group
    },
    individualTexts: {
      ...individualTexts
    },
    customCss: {
      ...customCss
    },
    productionNotice: h(BannerProductionNotice, {
      isPro: isPro,
      isLicensed: isLicensed,
      isDevLicense: isDevLicense,
      i18n: bannerI18n
    }),
    pageIdToPermalink,
    consentForwardingExternalHosts,
    paintMode,
    pageByIdUrl,
    groups,
    poweredLink,
    isTcf,
    isDataProcessingInUnsafeCountries,
    dataProcessingInUnsafeCountriesSafeCountries,
    iso3166OneAlpha2,
    isAgeNotice,
    isListServicesNotice,
    blocker,
    designVersion: bannerDesignVersion,
    i18n: bannerI18n,
    keepVariablesInTexts: isCurrentlyInTranslationEditorPreview,
    affiliate,
    consent: {
      groups: {
        ...(userDecision === false ? {} : userDecision.consent)
      }
    },
    onUnblock: event => {
      adjustConsentComingFromContentBlocker(blocker, JSON.stringify(recorder.createReplay()));
      setVisualAsLastClickedVisual(event);
    }
  });
  usePlainCss([`.elementor-background-overlay ~ [${HTML_ATTRIBUTE_COOKIE_IDS}] { z-index: 99; }`].join(""));
  const BlockerContext = BlockerContextFactory.Context();
  return h(BlockerContext.Provider, {
    value: contextValue
  }, h(Blocker, null));
};
export { WebsiteBlocker };